import React from 'react'

import Page from '~/templates/Page'

const Agb = () => (
  <Page title="Datenschutzerklärung">
    <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze ist:</p>
    <p>
      NurEinBerg UG (haftungsbeschränkt) <br />
      Vertreten durch die Gesellschafter: Jens Herga, Alexander Hörl, Kai Zippe
    </p>
    <p>
      <br />
      <strong>Erfassung allgemeiner Informationen</strong>
    </p>
    <p>
      Wenn Sie auf unsere Webseite zugreifen, werden automatisch Informationen
      allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles)
      beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem,
      den Domainnamen Ihres Internet Service Providers und Ähnliches. Hierbei
      handelt es sich ausschließlich um Informationen, welche keine Rückschlüsse
      auf Ihre Person zulassen. Diese Informationen sind technisch notwendig, um
      von Ihnen angeforderte Inhalte von Webseiten korrekt auszuliefern und
      fallen bei Nutzung des Internets zwingend an. Anonyme Informationen dieser
      Art werden von uns statistisch ausgewertet, um unseren Internetauftritt
      und die dahinter stehende Technik zu optimieren.
    </p>
    <p>
      <b>Erbringung kostenpflichtiger Leistungen</b>
    </p>
    <p>
      Zur Erbringung kostenpflichtiger Leistungen werden von uns zusätzliche
      Daten erfragt, wie z.B. Zahlungsangaben.
    </p>
    <p>
      Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden
      wir dem aktuellen Stand der Technik entsprechende
      Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
    </p>
    <p>
      <b>Newsletter</b>
    </p>
    <p>
      Bei der Anmeldung zum Bezug unseres Newsletters werden die von Ihnen
      angegebenen Daten ausschließlich für diesen Zweck verwendet. Abonnenten
      können auch über Umstände per E-Mail informiert werden, die für den Dienst
      oder die Registrierung relevant sind (Beispielsweise Änderungen des
      Newsletterangebots oder technische Gegebenheiten).
    </p>
    <p>
      Für eine wirksame Registrierung benötigen wir eine valide E-Mail-Adresse.
      Um zu überprüfen, dass eine Anmeldung tatsächlich durch den Inhaber einer
      E-Mail Adresse erfolgt, setzen wir das „double-opt-in“ Verfahren ein.
      Hierzu protokollieren wir die Bestellung des Newsletters, den Versand
      einer Bestätigungsmail und den Eingang der hiermit angeforderten Antwort.
      Weitere Daten werden nicht erhoben. Die Daten werden ausschließlich für
      den Newsletter-Versand verwendet und nicht an Dritte weitergegeben.
    </p>
    <p>
      Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer
      Nutzung für den Newsletterversand können Sie jederzeit widerrufen. In
      jedem Newsletter findet sich dazu ein entsprechender Link. Außerdem können
      Sie sich jederzeit auch direkt auf dieser Webseite abmelden oder uns Ihren
      entsprechenden Wunsch über die am Ende dieses Dokuments angegebene
      Kontaktmöglichkeiten mitteilen.
    </p>
    <p>
      <b>Kontaktformular</b>
    </p>
    <p>
      Treten Sie per E-Mail oder Kontaktformular mit uns in Kontakt, werden die
      von Ihnen gemachten Angaben zum Zwecke der Bearbeitung der Anfrage sowie
      für mögliche Anschlussfragen gespeichert.
    </p>
    <p>
      <b>Verwendung von Google Analytics</b>
    </p>
    <p>
      Diese Webseite benutzt Google Analytics, einen Webanalysedienst der Google
      Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien,
      die auf Ihrem Computer gespeichert werden und die eine Analyse der
      Benutzung der Webseite durch Sie ermöglichen. Die durch den Cookie
      erzeugten Informationen über Ihre Benutzung dieser Webseite werden in der
      Regel an einen Server von Google in den USA übertragen und dort
      gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf diesen
      Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb von
      Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
      Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in
      Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den
      USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Webseite
      wird Google diese Informationen benutzen, um Ihre Nutzung der Webseite
      auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen
      und um weitere mit der Webseitennutzung und der Internetnutzung verbundene
      Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. Die im
      Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
      nicht mit anderen Daten von Google zusammengeführt. Sie können die
      Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
      Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in
      diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Webseite
      vollumfänglich werden nutzen können. Sie können darüber hinaus die
      Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite
      bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung
      dieser Daten durch Google verhindern, indem sie das unter dem folgenden
      Link verfügbare Browser-Plugin herunterladen und installieren:
    </p>
    <p>
      Link:{' '}
      <a
        href="http://tools.google.com/dlpage/gaoptout?hl=de"
        target="_blank"
        rel="noopener noreferrer"
      >
        Browser Add On zur Deaktivierung von Google Analytics
      </a>
    </p>
    <p>
      Zusätzlich oder als Alternative zum Browser-Add-On können Sie das Tracking
      durch Google Analytics auf unseren Seiten unterbinden,{' '}
      <a
        title="Google Analytics Opt-Out-Cookie setzen"
        href="http://tools.google.com/dlpage/gaoptout?hl=de"
      >
        {' '}
        indem Sie diesen Link anklicken
      </a>
      . Dabei wird ein Opt-Out-Cookie auf Ihrem Gerät installiert. Damit wird
      die Erfassung durch Google Analytics für diese Website und für diesen
      Browser zukünftig verhindert, so lange der Cookie in Ihrem Browser
      installiert bleibt.
    </p>
    <p>
      <b>Einsatz von Google Remarketing</b>
    </p>
    <p>
      Diese Webseite verwendet die Remarketing-Funktion der Google Inc. Die
      Funktion dient dazu, Webseitenbesuchern innerhalb des
      Google-Werbenetzwerks interessenbezogene Werbeanzeigen zu präsentieren. Im
      Browser des Webseitenbesuchers wird ein sog. „Cookie“ gespeichert, der es
      ermöglicht, den Besucher wiederzuerkennen, wenn dieser Webseiten aufruft,
      die dem Werbenetzwerk von Google angehören. Auf diesen Seiten können dem
      Besucher Werbeanzeigen präsentiert werden, die sich auf Inhalte beziehen,
      die der Besucher zuvor auf Webseiten aufgerufen hat, die die Remarketing
      Funktion von Google verwenden.
      <br />
      Nach eigenen Angaben erhebt Google bei diesem Vorgang keine
      personenbezogenen Daten. Sollten Sie die Funktion Remarketing von Google
      dennoch nicht wünschen, können Sie diese grundsätzlich deaktivieren, indem
      Sie die entsprechenden Einstellungen unter{' '}
      <a href="http://www.google.com/settings/ads">
        http://www.google.com/settings/ads
      </a>{' '}
      vornehmen. Alternativ können Sie den Einsatz von Cookies für
      interessenbezogene Werbung über die Werbenetzwerkinitiative deaktivieren,
      indem Sie den Anweisungen unter{' '}
      <a href="http://www.networkadvertising.org/managing/opt_out.asp">
        http://www.networkadvertising.org/managing/opt_out.asp
      </a>{' '}
      folgen.
    </p>
    <p>
      <strong>Cookies</strong>
    </p>
    <p>
      Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“.
      Cookies sind kleine Textdateien, die von einem Webseitenserver auf Ihre
      Festplatte übertragen werden. Hierdurch erhalten wir automatisch bestimmte
      Daten wie z. B. IP-Adresse, verwendeter Browser, Betriebssystem über Ihren
      Computer und Ihre Verbindung zum Internet.
    </p>
    <p>
      Cookies können nicht verwendet werden, um Programme zu starten oder Viren
      auf einen Computer zu übertragen. Anhand der in Cookies enthaltenen
      Informationen können wir Ihnen die Navigation erleichtern und die korrekte
      Anzeige unserer Webseiten ermöglichen.
    </p>
    <p>
      In keinem Fall werden die von uns erfassten Daten an Dritte weitergegeben
      oder ohne Ihre Einwilligung eine Verknüpfung mit personenbezogenen Daten
      hergestellt.
    </p>
    <p>
      Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies
      betrachten. Internet-Browser sind regelmäßig so eingestellt, dass sie
      Cookies akzeptieren. Sie können die Verwendung von Cookies jederzeit über
      die Einstellungen Ihres Browsers deaktivieren. Bitte verwenden Sie die
      Hilfefunktionen Ihres Internetbrowsers, um zu erfahren, wie Sie diese
      Einstellungen ändern können. Bitte beachten Sie, dass einzelne Funktionen
      unserer Website möglicherweise nicht funktionieren, wenn Sie die
      Verwendung von Cookies deaktiviert haben.
    </p>
    <p>
      <strong>Google AdWords</strong>
    </p>
    <p>
      Unsere Webseite nutzt das Google Conversion-Tracking. Sind Sie über eine
      von Google geschaltete Anzeige auf unsere Webseite gelangt, wird von
      Google Adwords ein Cookie auf Ihrem Rechner gesetzt. Das Cookie für
      Conversion-Tracking wird gesetzt, wenn ein Nutzer auf eine von Google
      geschaltete Anzeige klickt. Diese Cookies verlieren nach 30 Tagen ihre
      Gültigkeit und dienen nicht der persönlichen Identifizierung. Besucht der
      Nutzer bestimmte Seiten unserer Website und das Cookie ist noch nicht
      abgelaufen, können wir und Google erkennen, dass der Nutzer auf die
      Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde. Jeder
      Google AdWords-Kunde erhält ein anderes Cookie. Cookies können somit nicht
      über die Websites von AdWords-Kunden nachverfolgt werden. Die mithilfe des
      Conversion-Cookies eingeholten Informationen dienen dazu,
      Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für
      Conversion-Tracking entschieden haben. Die Kunden erfahren die
      Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer
      mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden.
      Sie erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich
      identifizieren lassen.
    </p>
    <p>
      <em>
        Möchten Sie nicht am Tracking teilnehmen, können Sie das hierfür
        erforderliche Setzen eines Cookies ablehnen – etwa per
        Browser-Einstellung, die das automatische Setzen von Cookies generell
        deaktiviert oder Ihren Browser so einstellen, dass Cookies von der
        Domain „googleleadservices.com“ blockiert werden.
      </em>
    </p>
    <p>
      Bitte beachten Sie, dass Sie die Opt-out-Cookies nicht löschen dürfen,
      solange Sie keine Aufzeichnung von Messdaten wünschen. Haben Sie alle Ihre
      Cookies im Browser gelöscht, müssen Sie das jeweilige Opt-out Cookie
      erneut setzen.
    </p>
    <p>
      <strong>
        Ihre Rechte auf Auskunft, Berichtigung, Sperre, Löschung und Widerspruch
      </strong>
    </p>
    <p>
      Sie haben das Recht, jederzeit Auskunft über Ihre bei uns gespeicherten
      personenbezogenen Daten zu erhalten. Ebenso haben Sie das Recht auf
      Berichtigung, Sperrung oder, abgesehen von der vorgeschriebenen
      Datenspeicherung zur Geschäftsabwicklung, Löschung Ihrer personenbezogenen
      Daten. Bitte wenden Sie sich dazu an unseren Datenschutzbeauftragten. Die
      Kontaktdaten finden Sie ganz unten.
    </p>
    <p>
      Damit eine Sperre von Daten jederzeit berücksichtigt werden kann, müssen
      diese Daten zu Kontrollzwecken in einer Sperrdatei vorgehalten werden. Sie
      können auch die Löschung der Daten verlangen, soweit keine gesetzliche
      Archivierungsverpflichtung besteht. Soweit eine solche Verpflichtung
      besteht, sperren wir Ihre Daten auf Wunsch.
    </p>
    <p>
      Sie können Änderungen oder den Widerruf einer Einwilligung durch
      entsprechende Mitteilung an uns mit Wirkung für die Zukunft vornehmen.
    </p>
    <p>
      <strong>Änderung unserer Datenschutzbestimmungen</strong>
    </p>
    <p>
      Wir behalten uns vor, diese Datenschutzerklärung gelegentlich anzupassen,
      damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um
      Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.
      B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann
      die neue Datenschutzerklärung.
    </p>
    <p>
      <strong>Fragen an den Datenschutzbeauftragten</strong>
    </p>
    <p>
      Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail
      oder wenden Sie sich direkt an unseren Datenschutzbeauftragten:
    </p>
    <p>Kontaktdaten:</p>
    <p>
      <em>
        Die Datenschutzerklärung wurde mit dem{' '}
        <a href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/">
          Datenschutzerklärungs-Generator der activeMind AG erstellt
        </a>
        .
      </em>
    </p>
  </Page>
)

export default Agb
